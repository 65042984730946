import type { Language } from '@elseu/sdu-titan-product-site-components';
import { Trans } from '@lingui/macro';

export const getLanguages = (localesToFilterBy?: string | string[]): Language[] => {
  const languages: Language[] = [
    {
      label: <Trans>Nederlands (Nederland)</Trans>,
      locale: 'nl-NL',
    },
    {
      label: <Trans>Frans (Frankrijk)</Trans>,
      locale: 'fr-FR',
    },
    {
      label: <Trans>Spaans (Spanje)</Trans>,
      locale: 'es-ES',
    },
    {
      label: <Trans>Engels (Verenigd Koningkrijk)</Trans>,
      locale: 'en-GB',
    },
    {
      label: <Trans>Italiaans (Italië)</Trans>,
      locale: 'it-IT',
    },
    {
      label: <Trans>Duits (Duitsland)</Trans>,
      locale: 'de-DE',
    },
    {
      label: <Trans>Frans (België)</Trans>,
      locale: 'fr-BE',
    },
    {
      label: <Trans>Nederlands (België)</Trans>,
      locale: 'nl-BE',
    },
  ];

  if (process.env.NODE_ENV !== 'production') {
    languages.push({
      label: <Trans>Dev</Trans>,
      locale: 'dev',
    });
  }

  if (localesToFilterBy) {
    return languages.filter(({ locale }) =>
      Array.isArray(localesToFilterBy)
        ? localesToFilterBy.includes(locale)
        : localesToFilterBy === locale,
    );
  }

  return languages;
};
