import { HomePage as HomePageComponent } from '@elseu/sdu-titan-product-site-components';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React from 'react';

import { getLanguages } from '@/translations/languages';

const Languages = () => {
  const { locales } = useRouter();
  const languages = getLanguages(locales);

  return (
    <ul>
      {languages.map(({ locale, label }) => (
        <li key={locale}>
          <Link href="/" locale={locale}>
            {label}
          </Link>
        </li>
      ))}
    </ul>
  );
};

const HomePage = () => {
  const shouldRenderLanguages = process.env.NODE_ENV === 'development';

  return (
    <>
      {shouldRenderLanguages && <Languages />}
      <HomePageComponent />
    </>
  );
};

export default HomePage;
